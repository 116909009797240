import React from "react"
import { graphql } from "gatsby"

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import FragmentProductIndex from '../../model/fragments/allCommerceProductDefault/product-index'
import ContextProductIndexWrapper from './../../s4n/context/ProductIndex/wrapper-class'
import ProductIndex from "./../../components/s4n/Shop"



const ComponentStateless = (props) => {
    const { data: { index }, pageContext, intl } = props;

    // console.log(pageContext)
    // console.log(index.edges)

    const propsData = {
      pageContext,
      aDataCollectionTranslated: index.edges,
    };

    return (
      <ContextProductIndexWrapper intl={intl} pageContext={pageContext}>
          <ProductIndex propsData={propsData} />
      </ContextProductIndexWrapper>
    )
}

export default injectIntl(ComponentStateless)

export const query = graphql`
    query (
        $skip: Int!, 
        $limit: Int!,
        $langcode: String!,
    ) {
        index: allCommerceProductDefault (
            filter: {
                # this node is available in graphql database since it is published, we need to filter it out here
                title: {
                    ne: "SOFT4NET_DO_NOT_DELETE"
                }
                langcode: {
                    eq: $langcode
                }
            }
            sort: {
              fields: [title]
              order: ASC
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentProductIndex
                }
            }
        }
    }
`